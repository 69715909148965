<template>
  <div id="app">
    <header>
      <div class="header-container">
        <div class="logo-container">
          <img :src="require('@/assets/logo.png')" alt="Dualytics LLC Logo" class="logo" />
          <h1>Dualytics LLC</h1>
        </div>
        <nav>
          <ul :class="{ 'nav-links': true, 'show': isMenuOpen }">
            <!-- <li><a href="#home" class="nav-link">Home</a></li> -->
            <li><a href="#about" class="nav-link">About</a></li>
            <li><a href="#services" class="nav-link">Services</a></li>
            <li><a href="#testimonials-contact" class="nav-link">Contact</a></li>
          </ul>
          <div class="hamburger" @click="toggleMenu">
            <div class="line"></div>
            <div class="line"></div>
            <div class="line"></div>
          </div>
        </nav>
      </div>
    </header>

    <main>
      <!-- Home Section -->
      <section id="home" class="home">
        <div class="text-content">
          <h2>Dualytics LLC</h2>
          <p>
          Providing the tools you need to make data-driven decisions
          </p>
        </div>
      </section>

      <!-- About Section -->
      <section id="about" class="about">
        <div class="content-container">
          <div class="text-content">
            <h3>About Us</h3>
            <p>
            At Dualytics, our mission is to provide actionable insights and
            strategic solutions that drive growth and innovation.  With over a
            decade of industry experience and a background in computational
            mathematics, we are uniquely positioned to help your business unlock
            the full potential of its data.
            </p>
            <p>
            We specialize in machine learning, data analysis and reporting,
            custom data visualization, and data pipeline automation. Whether
            you're looking to optimize your operations or gain a competitive
            edge, Dualytics LLC is your partner in transforming data into
            valuable business outcomes
            </p>
          </div>
          <img :src="require('@/assets/logo.png')" alt="About Image" class="section-image" />
        </div>
      </section>

      <!-- Services Section -->
      <section id="services" class="services">
        <div class="service-grid">
          <div class="service-box">
            <h4>Automation</h4>
            <p>
            Automate repetitive and time-consuming data processing tasks,
            allowing you to focus on making informed, data-driven decisions.
            We provide ongoing monitoring and maintenance of your data pipelines
            to ensure continuous operation.
            </p>
          </div>
          <div class="service-box">
            <h4>Data Analysis</h4>
            <p>
            Our analyses enable you to identify opportunities, mitigate risks,
            and optimize operations. Detailed reports help you make strategic
            decisions targeting your specific business goals.
            </p>
          </div>
          <div class="service-box">
            <h4>Custom Dashboards</h4>
            <p>
            We work with you to design dashboards that allow you to explore your
            data dynamically. These dashboards allow you to view your data form
            different perspectives with drill-down capabilities.
            </p>
          </div>
          <div class="service-box">
            <h4>Machine Learning</h4>
            <p> 
            Whether it’s forecasting, classification, regression, or clustering,
            our models are built to solve specific problems.  We develop
            tailor-made machine learning models that fit your unique business
            needs. 
            </p>
          </div>
        </div>
      </section>

      <!-- Testimonials and Contact Section -->
      <section id="testimonials-contact" class="testimonials-contact">
          <!--
        <section id="testimonials" class="testimonials">
          <h3>What Our Clients Say</h3>
          <div class="testimonial-grid">
            <div class="testimonial-box">
              <blockquote>
                <p>{{ testimonials[0].text }}</p>
                <footer>- {{ testimonials[0].author }}</footer>
              </blockquote>
            </div>
            <div class="testimonial-box">
              <blockquote>
                <p>{{ testimonials[1].text }}</p>
                <footer>- {{ testimonials[1].author }}</footer>
              </blockquote>
            </div>
            <div class="testimonial-box">
              <blockquote>
                <p>{{ testimonials[2].text }}</p>
                <footer>- {{ testimonials[2].author }}</footer>
              </blockquote>
            </div>
          </div>
        </section>
        -->
        <section id="contact" class="contact">
          <div class="text-content">
            <h3>Contact Us</h3>
            <form @submit.prevent="submitForm">
              <input type="text" v-model="name" placeholder="Your Name" required />
              <input type="email" v-model="email" placeholder="Your Email" required />
              <textarea v-model="message" rows="5" placeholder="Your Message" required></textarea>
              <button type="submit">Send Message</button>
            </form>
            <p>Email: info@dualytics.com </p>
          </div>
        </section>
      </section>
    </main>

    <footer>
      <p>&copy; 2024 Dualytics LLC. All rights reserved.</p>
    </footer>
  </div>
</template>

<script>
import { ref } from 'vue';

export default {
  setup() {
    const name = ref('');
    const email = ref('');
    const message = ref('');

    const testimonials = [
      { text: "Dualytics transformed our data into actionable insights that really helped our business grow. Their expertise and clear communication were invaluable!", author: "Alex Johnson, CEO of XYZ Corp" },
      { text: "We were struggling to make sense of our data until Dualytics stepped in. Their custom reports and visualizations made a huge difference!", author: "Jamie Lee, Founder of ABC Ltd" },
      { text: "The insights provided by Dualytics have been game-changing for our strategy. Highly recommended for any business looking to leverage their data effectively.", author: "Morgan Smith, Director of DEF Inc" },
    ];

    const submitForm = () => {
      alert('Form submitted!');
    };

    const isMenuOpen = ref(false);
    const toggleMenu = () => {
      isMenuOpen.value = !isMenuOpen.value;
    };

    return { name, email, message, testimonials, submitForm, isMenuOpen, toggleMenu };
  }
};
</script>

<style>
/* Global Styles */
body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

#app {
  text-align: center;
}

/* Header */
header {
  background-color: rgba(255, 255, 255, 0.9); /* Lighter color with transparency */
  color: #282c34;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 1000;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

.logo-container {
  display: flex;
  align-items: center;
}

.logo {
  height: 40px;
  margin-right: 10px;
}

h1 {
  margin: 0;
}

nav {
  margin: 0;
}

.nav-links {
  list-style: none;
  display: flex;
  gap: 20px;
  padding: 0;
  margin: 0;
}

.nav-links a {
  color: #282c34;
  text-decoration: none;
  font-weight: 500;
  position: relative;
  padding: 8px 12px; /* Increased padding for better click area */
  transition: background-color 0.3s, color 0.3s, box-shadow 0.3s;
  border-radius: 4px;
}

.nav-links a::before {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 2px;
  background-color: #61dafb;
  transform: scaleX(0);
  transform-origin: bottom left;
  transition: transform 0.3s ease;
}

.nav-links a:hover::before {
  transform: scaleX(1);
}

.nav-links a:hover {
  /* background-color: #61dafb; */
  color: #282c34;
  /* box-shadow: 0 0 0 2px #282c34 inset; /1* Add inset box-shadow for hover effect *1/ */
}

/* Hamburger menu styles */
.hamburger {
  display: none;
  cursor: pointer;
  flex-direction: column;
  gap: 5px;
  padding: 0 20px;
}

.hamburger .line {
  width: 25px;
  height: 3px;
  background-color: #000;
}

/* Responsive styles */
@media (max-width: 768px) {
  .nav-links {
    display: none;
    flex-direction: column;
    background-color: rgba(255, 255, 255, 0.9);
    position: absolute;
    top: 60px;
    right: 0;
    width: 100%;
    text-align: center;
    gap: 10px;
  }

  .nav-links.show {
    display: flex;
  }

  .hamburger {
    display: flex;
  }
}

/* Main Sections */
main {
  padding-top: 80px; /* Adjust padding to avoid content being hidden behind the fixed header */
}

/* Section Styles */
.content-container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 60px 20px;
  position: relative;
}

.section-image {
  width: 50%;
  max-width: 400px;
  height: auto;
  margin: 0 20px;
}

.text-content {
  width: 100%;
  text-align: center; /* Center text in Home section */
}

h2, h3 {
  margin-bottom: 20px;
}

ul {
  list-style-type: none;
  padding: 0;
}

ul li {
  margin-bottom: 10px;
}

/* Home Section */
.home {
  background-color: #f4f4f4;
  padding: 80px 20px;
}

.home .text-content h2 {
  font-size: 2.5rem;
}

.home .text-content p {
  font-size: 1.25rem;
}

/* About Section */
.about {
  background-color: #282c34;
  color: white;
  padding: 80px 20px;
}

.about .content-container {
  display: flex;
  align-items: start;
  justify-content: space-between;
  padding: 20px 20px;
}

@media (max-width: 768px) {
  .about .content-container {
      display: grid;
      grid-template-columns: 1fr;
      align-items: start;
      justify-content: center;
      padding: 20px 20px;
  }
}

.about img {
  padding: 40px ;
  justify-self: center;
}

.about .text-content h3 {
  font-size: 2rem;
}

.about .text-content {
  text-align: left; /* Align text to the left */
  font-size: 1.25rem;
}

.section-image {
  width: 20%;
  max-width: 400px;
  height: auto;
}

/* Services Section */
.services {
  background-image: linear-gradient(0deg, rgba(0,0,0,0.4), rgba(0,0,0,0.4)), url('@/assets/ai-generated-8540922_1920.jpg');
  background-position: center;
  color: #282c34;
  padding: 80px 20px;
}

.service-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
  justify-content: center;
  align-content: center;
}

.service-box {
  background-color: white;
  border: 1px solid #282c34;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0,0,0,0.2);
  font-size: 1rem;
}

/* Testimonials and Contact Section */
.testimonials-contact {
  display: flex;
  justify-content: space-around;
  padding: 80px 20px;
}

.testimonials {
  width: 45%;
  background-color: #f4f4f4;
  padding: 20px;
  border-radius: 8px;
}

.testimonials h3 {
  font-size: 2rem;
  margin-bottom: 20px;
}

.testimonial-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
}

.testimonial-box {
  background-color: white;
  border: 1px solid #282c34;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0,0,0,0.2);
  font-size: 1rem;
}

.testimonial-box blockquote {
  margin: 0;
  font-style: italic;
  color: #333;
}

.testimonial-box footer {
  text-align: right;
  font-weight: bold;
  color: #666;
}

.contact {
  width: 100%;
  max-width: 560px;
  background-color: #282c34;
  color: white;
  padding: 20px;
  border-radius: 8px;
}

.contact .text-content h3 {
  font-size: 2rem;
}

.contact form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.contact input, .contact textarea {
  width: 100%;
  max-width: 500px;
  margin: 10px 0;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.contact button {
  padding: 10px 20px;
  font-size: 1.25rem;
  background-color: #61dafb;
  color: white;
  border: none;
  cursor: pointer;
}

.contact button:hover {
  background-color: #21a1f1;
}

footer {
  background-color: #282c34;
  color: white;
  padding: 20px;
  text-align: center;
  margin-top: 20px;
}
</style>

